//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data () {
        return {
            dialogVisible: false
        }
    },
    methods: {
        dialogShow() {
            this.dialogVisible = true;
        }
    },
    mounted: function() {
        
    }
}
